<template>
  <div class="">
    <table class="table ">
      <thead>
        <tr>
          <th></th>
          <th>اسم المادة</th>
          <th>التكلفة</th>
        </tr>
      </thead>
      <tbody >
        <tr v-for="subject in offer.subjects" :key="subject.id">
          <td>
              <vmd-checkbox v-model="subject.checked"></vmd-checkbox>
              <input type="checkbox" v-model="subject.checked" class="form-control"/>
          </td>
          <td>
              <span class="course-name">{{ subject.name }}</span>
          </td>
          <td>
              {{ $filters.number(subject.pivot.subject_cost) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import setMaterialInput from '@/assets/js/material-input.js'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
export default {
  name: 'OfferSubject',
  components: {
    VmdCheckbox
  },
  props: {
    modelValue: Array
  },
  emits: ['update:modelValue'],
  mounted () {
    setMaterialInput()
  },
  computed: {
    offer: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
<style>
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
  }
.modal-content {
    width: 50% !important;
  }
</style>
